<template>
  <div class="pt-2 mt-5">
    <div class="mt-2">
      <h1>Cree su nueva contraseña</h1>
    </div>
    <div class="div-center">
      <div class="d-block w-50">
        <div class="d-flex">
          <div class="d-blok w-100">
            <b-form-input
              v-model="$v.user.password.$model"
              :state="validateState('password')"
              :type="!new_password_visibility ? 'password' : 'text'"
              placeholder="Nueva contraseña"
              autocomplete="off"
              :class="{ 'my-2': !new_password_visibility }"
              aria-describedby="input-password-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-password-feedback">
              <div class="d-block text-left">
                La contraseña de estar compuesta al menos por:
                <div v-if="!/[a-z]/.test(user.password)" class="ml-4">
                  - Al menos una letra en minúsculas.
                </div>
                <div v-if="!/[A-Z]/.test(user.password)" class="ml-4">
                  - Al menos una letra en mayúsculas.
                </div>
                <div v-if="!/[0-9]/.test(user.password)" class="ml-4">
                  - Al menos un número.
                </div>
                <div v-if="!/[.#?!@$%^&*-]/.test(user.password)" class="ml-4">
                  - Al menos un simbolo.
                </div>
                <div
                  v-if="
                    $v.user.password.$params.minLength.min >
                    user.password.length
                  "
                  class="ml-4"
                >
                  - Debe tener
                  {{ $v.user.password.$params.minLength.min }} carácteres como
                  mínimo.
                </div>
              </div>
            </b-form-invalid-feedback>
          </div>
          <b-button
            class="password-visibility"
            @click="() => (new_password_visibility = !new_password_visibility)"
            ><b-icon v-if="new_password_visibility" icon="eye"></b-icon
            ><b-icon v-else icon="eye-slash"></b-icon
          ></b-button>
        </div>
        <div class="d-flex">
          <div class="d-block w-100">
            <b-form-input
              v-model="$v.user.confirm_password.$model"
              :state="validateState('confirm_password')"
              aria-autocomplete="off"
              :type="!confirm_password_visibility ? 'password' : 'text'"
              :class="{ 'my-2': !confirm_password_visibility }"
              placeholder="Vuelva a escribir la contraseña"
              aria-describedby="input-confirm-password-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-confirm-password-feedback"
              >Las contraseñas no son iguales.
            </b-form-invalid-feedback>
          </div>
          <b-button
            class="password-visibility"
            @click="
              () => (confirm_password_visibility = !confirm_password_visibility)
            "
            ><b-icon v-if="confirm_password_visibility" icon="eye"></b-icon
            ><b-icon v-else icon="eye-slash"></b-icon
          ></b-button>
        </div>
        <div class="row my-1">
          <div class="col" style="text-align: left">
            <b-button
              class="mr-1 w-100"
              size="sm"
              @click="
                () => {
                  $router.push({ name: 'Welcome' });
                }
              "
              >Cancelar</b-button
            >
          </div>
          <div class="col" style="text-align: right">
            <b-button
              v-if="!disable_button"
              class="mr-1 w-100"
              size="sm"
              variant="primary"
              @click="confirm"
              >Confimar contraseña</b-button
            >
            <b-button
              v-else
              class="mr-1 w-100"
              size="sm"
              variant="primary"
              disabled
              >Confimar contraseña</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "WelcomePasswordChange",
  mixins: [validationMixin],
  data() {
    return {
      email: String(this.$route.params.email),
      code: String(this.$route.params.code),
      new_password_visibility: false,
      confirm_password_visibility: false,
      user: {
        password: "",
        confirm_password: "",
      },
      disable_button: false,
    };
  },
  validations: {
    user: {
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[.#?!@$%^&*-]/.test(value);
        },
      },
      confirm_password: { required, sameAsPassword: sameAs("password") },
    },
  },
  computed: {
    ...mapGetters({
      isLoged: "isLoged",
    }),
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.user[key];
      return $dirty ? !$error : null;
    },
    confirm() {
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      }
      const payload = {
        new_password: this.user.password,
        repeated_password: this.user.confirm_password,
        email: this.email,
        code: this.code,
        is_welcome_request: true,
      };
      this.disable_button = true;
      this.$restful
        .Post(`/authentication/reset-password/`, payload)
        .then(() => {
          this.disable_button = false;
          this.$router.push({ name: "Login" });
        })
        .catch((err) => {
          this.disable_button = false;
          if (err.response && err.response.data)
            this.$swal.fire("Error", `${err.response.data}`, "error");
        });
    },
  },
  created() {
    if (this.isLoged) {
      this.$logOut();
    }
  },
};
</script>

<style scoped>
.div-center {
  text-align: -webkit-center;
}
.password-visibility {
  height: 2.35em;
  margin-top: 8px;
  margin-left: 1%;
}
</style>